import React from "react";

type Fill = "dark" | "light";

const Logo = (props: { fill: Fill }) => {
  return props.fill === "dark" ? (
    <svg
      width="49"
      height="24"
      viewBox="0 0 49 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16502_1042)">
        <path d="M7.38994 12H3.68994V24H7.38994V12Z" fill="#2D3748" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0799 0V2.77C9.53994 2.77 7.38994 3.14 7.38994 4.62V8.31H3.68994V4.62C3.68994 3.85 3.87994 2.69 4.88994 1.73C5.90994 0.77 7.75994 0 11.0799 0Z"
          fill="#2D3748"
        />
        <path
          d="M17.54 16.35V24H13.85V16.35C14.43 16.53 15.05 16.62 15.69 16.62C16.33 16.62 16.95 16.53 17.54 16.35Z"
          fill="#2D3748"
        />
        <path
          d="M24 8.31004H19.93C19.21 6.68004 17.58 5.54004 15.69 5.54004C13.8 5.54004 12.18 6.68004 11.47 8.31004H0L3.69 12H11.47C12.18 13.63 13.8 14.77 15.69 14.77C17.58 14.77 19.21 13.63 19.93 12H22.62L32.31 24H36.93L24 8.31004ZM15.69 12C14.67 12 13.85 11.17 13.85 10.15C13.85 9.13004 14.67 8.31004 15.69 8.31004C16.71 8.31004 17.54 9.13004 17.54 10.15C17.54 11.17 16.71 12 15.69 12Z"
          fill="#A100FF"
        />
        <path
          d="M32.77 13.3901L36.9199 8.31006H32.2999L30.45 10.6201L32.7599 13.3901H32.77Z"
          fill="#2D3748"
        />
        <path
          d="M23.54 18.92L19.39 24H24.01L25.86 21.69L23.55 18.92H23.54Z"
          fill="#2D3748"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.77 8.31006H42.46V12.0001C42.46 10.7701 43.75 8.31006 48.92 8.31006V12.0001C46.77 12.0001 42.46 13.1101 42.46 17.5401V24.0001H41.54L38.77 20.7701V8.31006Z"
          fill="#2D3748"
        />
      </g>
      <defs>
        <clipPath id="clip0_16502_1042">
          <rect width="48.92" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="49"
      height="24"
      viewBox="0 0 49 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16502_1073)">
        <path d="M7.38994 12H3.68994V24H7.38994V12Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0799 0V2.77C9.53994 2.77 7.38994 3.14 7.38994 4.62V8.31H3.68994V4.62C3.68994 3.85 3.87994 2.69 4.88994 1.73C5.90994 0.77 7.75994 0 11.0799 0Z"
          fill="white"
        />
        <path
          d="M17.54 16.35V24H13.85V16.35C14.43 16.53 15.05 16.62 15.69 16.62C16.33 16.62 16.95 16.53 17.54 16.35Z"
          fill="white"
        />
        <path
          d="M24 8.31004H19.93C19.21 6.68004 17.58 5.54004 15.69 5.54004C13.8 5.54004 12.18 6.68004 11.47 8.31004H0L3.69 12H11.47C12.18 13.63 13.8 14.77 15.69 14.77C17.58 14.77 19.21 13.63 19.93 12H22.62L32.31 24H36.93L24 8.31004ZM15.69 12C14.67 12 13.85 11.17 13.85 10.15C13.85 9.13004 14.67 8.31004 15.69 8.31004C16.71 8.31004 17.54 9.13004 17.54 10.15C17.54 11.17 16.71 12 15.69 12Z"
          fill="#A100FF"
        />
        <path
          d="M32.77 13.3901L36.9199 8.31006H32.2999L30.45 10.6201L32.7599 13.3901H32.77Z"
          fill="white"
        />
        <path
          d="M23.54 18.92L19.39 24H24.01L25.86 21.69L23.55 18.92H23.54Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.77 8.31006H42.46V12.0001C42.46 10.7701 43.75 8.31006 48.92 8.31006V12.0001C46.77 12.0001 42.46 13.1101 42.46 17.5401V24.0001H41.54L38.77 20.7701V8.31006Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16502_1073">
          <rect width="48.92" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
