import React from "react";

const Logo = () => (
  <svg
    width="505"
    height="348"
    viewBox="0 0 505 348"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M100.562 17.1991C50.3568 33.1858 13.1019 73.7391 5.76226 122.799C-0.420039 164.144 -17.2822 253.758 87.1577 275.332C254.827 309.858 448.119 290.864 488.568 222.532C529.016 154.2 488.568 31.9538 384.714 15.7324C324.751 6.37512 198.629 -14.0409 100.562 17.1991Z"
      fill="#88AFEF"
    />
    <path
      opacity="0.45"
      d="M252 347.772C383.592 347.772 490.268 341.008 490.268 332.665C490.268 324.322 383.592 317.559 252 317.559C120.408 317.559 13.7324 324.322 13.7324 332.665C13.7324 341.008 120.408 347.772 252 347.772Z"
      fill="#E6E6E6"
    />
    <path
      d="M322.993 101.914H258.797V150.798H322.993V101.914Z"
      fill="#CBD5E0"
    />
    <path
      d="M302.674 105.625H264.408V122.594H302.674V105.625Z"
      fill="#FFD200"
    />
    <path
      d="M272.187 125.674H264.408V130.455H272.187V125.674Z"
      fill="#B3B3B3"
    />
    <path
      d="M282.326 125.674H274.547V130.455H282.326V125.674Z"
      fill="#B3B3B3"
    />
    <path
      d="M292.537 125.674H284.758V130.455H292.537V125.674Z"
      fill="#B3B3B3"
    />
    <path
      d="M302.674 125.674H294.895V130.455H302.674V125.674Z"
      fill="#B3B3B3"
    />
    <path d="M272.187 132.48H264.408V137.262H272.187V132.48Z" fill="#B3B3B3" />
    <path d="M282.326 132.48H274.547V137.262H282.326V132.48Z" fill="#B3B3B3" />
    <path d="M292.537 132.48H284.758V137.262H292.537V132.48Z" fill="#B3B3B3" />
    <path d="M302.674 132.48H294.895V137.262H302.674V132.48Z" fill="#B3B3B3" />
    <path
      d="M272.187 139.271H264.408V144.053H272.187V139.271Z"
      fill="#B3B3B3"
    />
    <path
      d="M282.326 139.271H274.547V144.053H282.326V139.271Z"
      fill="#B3B3B3"
    />
    <path
      d="M292.537 139.271H284.758V144.053H292.537V139.271Z"
      fill="#B3B3B3"
    />
    <path
      d="M302.674 139.271H294.895V144.053H302.674V139.271Z"
      fill="#B3B3B3"
    />
    <path
      d="M314.542 116.596H309.766C307.63 116.596 305.898 118.329 305.898 120.468V135.398C305.898 137.537 307.63 139.27 309.766 139.27H314.542C316.678 139.27 318.41 137.537 318.41 135.398V120.468C318.41 118.329 316.678 116.596 314.542 116.596Z"
      fill="#B3B3B3"
    />
    <path d="M316.62 121.494H307.684V135.574H316.62V121.494Z" fill="#24285B" />
    <path
      d="M203.054 172.902C202.087 198.276 196.682 219.102 185.006 238.125C183.453 240.662 181.988 241.352 180.303 240.34C179.426 239.67 178.833 238.696 178.64 237.609C178.447 236.523 178.67 235.403 179.263 234.473C180.947 231.452 182.852 228.606 184.361 225.424C194.704 203.996 198.791 181.526 195.231 157.854C194.169 150.856 192.034 144.063 188.902 137.717C188.372 136.726 188.049 135.639 187.95 134.52C187.813 133.701 187.987 132.86 188.437 132.163C188.886 131.466 189.58 130.962 190.382 130.75C192.228 130.266 193.312 131.454 193.986 132.965C197.509 139.878 199.926 147.302 201.15 154.965C202.19 162.005 202.659 169.045 203.054 172.902Z"
      fill="#88AFEF"
    />
    <path
      d="M151.34 89.6088C151.4 90.0878 151.358 90.574 151.218 91.0357C151.077 91.4974 150.841 91.9242 150.524 92.2882C150.207 92.6522 149.818 92.9453 149.38 93.1483C148.943 93.3512 148.468 93.4596 147.985 93.4662C127.622 94.1555 112.913 104.466 101.721 120.702C97.0447 127.632 93.5221 135.274 91.2899 143.333C90.4695 146.119 89.1657 147.278 87.3784 146.868C86.8924 146.693 86.4462 146.423 86.0662 146.073C85.6861 145.723 85.3799 145.301 85.1655 144.83C84.9512 144.36 84.833 143.852 84.818 143.335C84.8031 142.818 84.8916 142.304 85.0783 141.822C92.5352 116.889 106.189 97.1768 131.958 88.3475C136.881 86.6608 142.316 86.4262 147.546 85.7808C149.949 85.4142 151.355 87.0862 151.34 89.6088Z"
      fill="#88AFEF"
    />
    <path
      d="M215.155 164.176C215.155 165.818 215.155 167.314 215.155 168.796C215.155 171.333 213.792 173.078 211.961 173.093C210.13 173.108 208.841 171.362 208.826 168.781C208.81 160.43 207.657 152.121 205.398 144.082C200.314 126.482 191.217 111.816 175.644 101.681C174.457 100.918 173.183 100.288 171.996 99.5396C171.127 99.045 170.462 98.2567 170.121 97.3159C169.78 96.3752 169.785 95.3436 170.135 94.4062C170.941 92.0156 172.67 91.3116 174.955 92.5436C181.854 96.3062 188.026 101.273 193.18 107.21C204.768 120.484 211.199 136.104 213.924 153.366C214.481 157.004 214.759 160.67 215.155 164.176Z"
      fill="#88AFEF"
    />
    <path
      d="M148.512 116.934C149.537 116.934 150.958 116.934 152.35 117.183C153.31 117.398 154.15 117.976 154.696 118.795C155.242 119.614 155.451 120.613 155.28 121.583C155.283 122.062 155.185 122.536 154.991 122.974C154.798 123.412 154.514 123.804 154.158 124.124C153.803 124.444 153.383 124.685 152.927 124.831C152.472 124.977 151.99 125.025 151.515 124.971C139.795 123.827 130.771 128.608 123.519 137.306C116.267 146.003 113.117 156.563 112.693 167.886C112.708 168.662 112.649 169.438 112.517 170.203C112.449 170.942 112.114 171.63 111.574 172.138C111.034 172.646 110.327 172.938 109.587 172.96C108.85 172.992 108.131 172.734 107.583 172.24C107.035 171.746 106.702 171.057 106.657 170.32C106.476 168.369 106.476 166.405 106.657 164.454C107.77 150.315 112.517 137.746 122.889 127.714C126.229 124.301 130.216 121.59 134.615 119.739C139.015 117.888 143.739 116.934 148.512 116.934Z"
      fill="#88AFEF"
    />
    <path
      d="M168.612 190.956C168.612 192.085 168.612 193.215 168.612 194.344C168.612 196.779 167.352 198.465 165.594 198.553C163.836 198.641 162.415 196.911 162.283 194.373C162.122 191.059 162.063 187.729 161.785 184.429C161.464 177.712 160.013 171.097 157.492 164.864C156.63 162.855 155.481 160.982 154.079 159.305C153.401 158.434 152.545 157.717 151.567 157.204C150.59 156.691 149.514 156.393 148.413 156.33C147.311 156.268 146.208 156.442 145.179 156.841C144.15 157.241 143.219 157.856 142.447 158.645C141.079 159.881 139.983 161.387 139.226 163.068C138.47 164.749 138.07 166.569 138.052 168.413C138.096 169.19 138.096 169.969 138.052 170.745C137.73 172.461 136.924 173.943 134.975 173.957C134.562 173.968 134.15 173.893 133.766 173.737C133.383 173.582 133.035 173.349 132.745 173.053C132.455 172.757 132.229 172.405 132.081 172.018C131.933 171.631 131.866 171.218 131.884 170.804C131.73 168.691 131.848 166.566 132.236 164.483C133.701 157.149 137.246 151.283 144.937 148.98C147.018 148.346 149.232 148.283 151.345 148.799C153.459 149.315 155.395 150.391 156.95 151.913C161.052 155.565 163.221 160.405 164.788 165.524C167.211 173.788 168.498 182.344 168.612 190.956Z"
      fill="#88AFEF"
    />
    <path
      d="M189.753 189.24C186.823 212.458 178.208 234.208 159.632 251.178C157.347 253.26 155.94 253.466 154.549 251.999C153.776 251.069 153.397 249.874 153.493 248.668C153.589 247.462 154.152 246.342 155.061 245.546C157.772 242.862 160.599 240.28 163.06 237.376C174.268 224.176 180.318 208.556 182.97 191.543C183.189 190.076 183.307 188.742 183.497 187.348C183.849 184.796 185.123 183.3 186.837 183.388C188.551 183.476 189.928 185.324 189.753 189.24Z"
      fill="#88AFEF"
    />
    <path
      d="M94 169.308C94.5274 149.728 99.86 132.333 113.045 117.887C115.244 115.596 117.593 113.455 120.077 111.477C122.113 109.761 123.432 109.761 124.706 111.125C125.379 111.907 125.767 112.894 125.807 113.925C125.846 114.956 125.536 115.97 124.926 116.801C124.651 117.123 124.342 117.413 124.003 117.667C110.481 128.095 103.596 142.277 101.296 158.821C100.75 164.436 100.579 170.08 100.783 175.717C100.783 178.768 99.8453 180.66 97.8529 180.807C95.8605 180.953 94.6007 179.237 94.4249 176.113C94.2344 173.723 94.1172 171.317 94 169.308Z"
      fill="#88AFEF"
    />
    <path
      d="M147.793 133.199C151.551 133.197 155.263 134.035 158.656 135.651C162.05 137.268 165.04 139.622 167.41 142.542C168.221 143.741 168.56 145.199 168.362 146.634C168.236 147.194 167.977 147.716 167.608 148.155C167.238 148.594 166.768 148.938 166.238 149.157C165.64 149.195 165.042 149.107 164.481 148.897C163.921 148.687 163.411 148.361 162.985 147.939C157.96 142.791 152.042 140.093 144.849 141.457C137.333 142.923 132.484 147.837 129.1 154.437C128.865 155.176 128.524 155.877 128.089 156.519C127.327 157.238 126.302 158.177 125.423 158.147C124.894 158.057 124.393 157.845 123.959 157.53C123.524 157.215 123.168 156.804 122.918 156.329C122.404 155.257 122.32 154.028 122.683 152.897C126.8 142.953 133.392 135.707 144.453 133.566C145.559 133.386 146.674 133.263 147.793 133.199Z"
      fill="#88AFEF"
    />
    <path
      d="M148.512 109.278C146.329 109.483 144.498 109.615 142.652 109.835C139.59 110.217 138.081 109.322 137.686 106.77C137.29 104.218 138.726 102.473 141.583 102.033C148.981 100.905 156.545 101.687 163.558 104.306C170.111 106.711 176.061 110.52 180.991 115.467C181.95 116.45 182.5 117.76 182.529 119.134C182.365 120.482 181.675 121.71 180.61 122.551C179.409 123.475 178.12 122.551 177.08 121.583C171.222 115.789 163.796 111.842 155.72 110.231C153.244 109.689 150.695 109.601 148.512 109.278Z"
      fill="#88AFEF"
    />
    <path
      d="M115.887 202.368C115.929 212.354 114.573 222.297 111.858 231.906C111.053 234.664 109.705 235.852 107.903 235.324C106.101 234.796 104.973 232.508 105.72 230.044C108.364 221.222 109.634 212.046 109.485 202.837C109.353 197.498 108.562 192.16 108.02 186.821C107.871 185.851 107.822 184.868 107.874 183.888C108.079 182.216 108.885 180.866 110.804 180.778C111.557 180.745 112.294 181.004 112.862 181.501C113.43 181.998 113.784 182.696 113.851 183.448C114.495 187.07 115.052 190.708 115.433 194.36C115.755 197.352 115.755 200.402 115.887 202.368Z"
      fill="#88AFEF"
    />
    <path
      d="M189.912 165.994C190.008 167.327 190.008 168.665 189.912 169.998C189.663 171.743 188.711 173.166 186.748 173.034C185.927 172.966 185.163 172.584 184.617 171.966C184.071 171.348 183.785 170.543 183.818 169.719C183.437 165.994 183.334 162.224 182.646 158.572C180.993 148.958 176.305 140.127 169.27 133.375C168.198 132.311 167.429 130.979 167.044 129.518C166.92 129.141 166.875 128.742 166.91 128.347C166.945 127.951 167.06 127.567 167.247 127.218C167.435 126.868 167.692 126.56 168.002 126.313C168.311 126.065 168.668 125.883 169.051 125.778C169.676 125.691 170.313 125.735 170.92 125.906C171.528 126.078 172.094 126.374 172.581 126.775C177.953 131.513 182.164 137.424 184.887 144.052C187.911 150.99 189.615 158.431 189.912 165.994Z"
      fill="#88AFEF"
    />
    <path
      d="M155.002 208.688C154.092 222.57 151.504 236.29 147.296 249.549C146.505 252.057 145.245 252.996 143.443 252.556C141.641 252.116 140.513 249.769 141.158 247.496C142.139 243.888 143.282 240.324 144.19 236.701C146.595 227.188 148.101 217.47 148.688 207.676C148.688 207.544 148.688 207.397 148.688 207.25C148.893 204.713 150.241 203.012 151.999 203.056C153.757 203.1 155.104 204.962 155.046 207.632C155.046 207.984 155.017 208.336 155.002 208.688Z"
      fill="#88AFEF"
    />
    <path
      d="M129.834 201.53C129.834 203.437 129.834 205.343 129.834 207.25C129.732 209.553 128.237 211.283 126.538 211.21C124.838 211.137 123.608 209.435 123.608 207.206C123.515 196.19 122.308 185.212 120.004 174.441C119.927 174.098 119.878 173.75 119.857 173.399C119.682 171.111 120.839 169.219 122.553 168.999C124.267 168.779 125.659 170.041 126.157 172.417C128.178 182.004 129.408 191.741 129.834 201.53Z"
      fill="#88AFEF"
    />
    <path
      d="M92.6962 199.947C92.4911 201.986 91.9051 203.614 89.9274 203.981C87.9496 204.347 86.9974 202.91 86.4407 201.282C83.0576 191.003 81.3413 180.248 81.3571 169.426C81.3175 168.446 81.6063 167.48 82.1775 166.683C82.5332 166.283 82.9652 165.957 83.4482 165.725C83.9312 165.493 84.4554 165.36 84.9903 165.334C85.7368 165.446 86.4148 165.833 86.8916 166.419C87.3684 167.005 87.6099 167.747 87.5687 168.502C87.7445 170.761 87.7592 173.019 87.8764 175.278C88.3463 182.972 89.8239 190.571 92.2714 197.879C92.448 198.561 92.5898 199.251 92.6962 199.947Z"
      fill="#88AFEF"
    />
    <path
      d="M179.644 183.255C179.644 185.441 179.644 187.655 179.644 189.826C179.644 191.997 178.296 193.463 176.611 193.507C176.151 193.474 175.702 193.35 175.289 193.142C174.877 192.934 174.51 192.647 174.209 192.297C173.908 191.946 173.68 191.54 173.536 191.101C173.393 190.662 173.337 190.198 173.373 189.738C173.373 187.479 173.681 185.191 173.373 182.977C172.524 176.054 171.44 169.161 170.443 162.253C170.154 161.331 170.154 160.343 170.443 159.422C171.088 158.41 172.319 157.163 173.271 157.178C173.885 157.307 174.458 157.586 174.938 157.99C175.419 158.395 175.792 158.912 176.025 159.495C177.212 164.13 178.208 168.823 178.955 173.546C179.468 176.743 179.497 180.014 179.746 183.255H179.644Z"
      fill="#88AFEF"
    />
    <path
      d="M141.846 207.471C141.597 211.153 141.29 215.655 140.953 220.173C140.903 220.735 140.795 221.29 140.63 221.83C140.499 222.534 140.116 223.166 139.552 223.606C138.988 224.046 138.282 224.265 137.569 224.221C136.846 224.193 136.159 223.9 135.64 223.396C135.121 222.893 134.806 222.215 134.756 221.493C134.587 220.443 134.587 219.374 134.756 218.325C135.696 209.682 135.617 200.959 134.521 192.335C134.42 191.572 134.46 190.796 134.639 190.047C134.741 189.345 135.095 188.704 135.634 188.243C136.172 187.782 136.86 187.532 137.569 187.539C138.297 187.536 139.002 187.804 139.543 188.292C140.085 188.78 140.426 189.453 140.499 190.179C140.906 192.47 141.19 194.781 141.348 197.102C141.568 200.285 141.656 203.467 141.846 207.471Z"
      fill="#88AFEF"
    />
    <path
      d="M103.859 201.619C103.097 207.339 102.394 213.059 101.588 218.779C101.554 219.555 101.305 220.306 100.871 220.949C100.24 221.754 99.3491 222.315 98.3507 222.533C97.8544 222.491 97.374 222.337 96.9454 222.083C96.5167 221.829 96.1508 221.482 95.8749 221.067C95.3551 219.929 95.2065 218.656 95.45 217.429C96.9979 209.883 97.491 202.158 96.915 194.476C96.8419 193.774 96.8419 193.066 96.915 192.364C97.1641 190.692 97.9991 189.431 99.845 189.299C100.242 189.279 100.639 189.34 101.011 189.478C101.384 189.617 101.724 189.83 102.012 190.104C102.3 190.379 102.529 190.709 102.685 191.074C102.842 191.44 102.922 191.834 102.922 192.232C103.273 195.371 103.39 198.553 103.61 201.707L103.859 201.619Z"
      fill="#88AFEF"
    />
    <path
      d="M213.689 185.867C212.913 194.343 211.016 202.678 208.049 210.654C207.228 212.824 205.881 213.675 204.298 213.191C203.438 212.849 202.731 212.205 202.31 211.379C201.889 210.553 201.784 209.601 202.013 208.703C202.35 207.134 203.097 205.638 203.478 204.068C204.782 198.29 206.027 192.511 207.272 186.718C207.433 185.97 207.448 185.178 207.639 184.415C208.034 182.86 208.869 181.643 210.569 181.672C212.268 181.702 213.176 182.919 213.499 184.488C213.599 184.942 213.663 185.403 213.689 185.867Z"
      fill="#88AFEF"
    />
    <path
      d="M154.563 190.764C154.636 191.819 154.636 192.878 154.563 193.932C154.328 195.502 153.581 196.866 151.823 196.968C151.433 197.033 151.033 197.014 150.65 196.913C150.268 196.812 149.911 196.63 149.604 196.381C149.296 196.131 149.045 195.819 148.867 195.466C148.689 195.112 148.588 194.724 148.571 194.328C148.204 191.615 148.204 188.843 147.867 186.115C147.267 181.348 146.52 176.611 145.831 171.859C145.831 171.507 145.699 171.17 145.685 170.818C145.531 169.864 145.735 168.888 146.257 168.076C146.779 167.264 147.582 166.673 148.512 166.418C150.094 166.212 151.618 167.503 151.97 169.747C153.054 176.714 154.006 183.71 155.002 190.691L154.563 190.764Z"
      fill="#88AFEF"
    />
    <path
      d="M167.748 208.762C166.853 216.669 164.978 224.433 162.166 231.876C161.899 232.41 161.503 232.869 161.015 233.212C160.526 233.555 159.961 233.772 159.368 233.842C158.766 233.683 158.207 233.39 157.735 232.983C157.263 232.577 156.89 232.068 156.643 231.495C156.341 230.283 156.397 229.009 156.804 227.828C158.269 221.243 159.866 214.628 161.405 208.102C161.405 207.896 161.405 207.691 161.507 207.486C161.961 205.286 163.324 203.98 164.935 204.156C166.547 204.332 167.792 206.092 167.748 208.762Z"
      fill="#88AFEF"
    />
    <path
      d="M128.939 222.533C127.108 229.25 125.321 235.968 123.402 242.656C123.347 242.994 123.221 243.317 123.032 243.604C122.843 243.89 122.596 244.133 122.307 244.317C122.018 244.502 121.693 244.623 121.354 244.672C121.015 244.722 120.67 244.7 120.34 244.606C119.197 244.051 118.295 243.096 117.805 241.922C117.571 240.834 117.642 239.702 118.011 238.652C119.314 233.034 120.692 227.432 122.039 221.829C122.157 221.345 122.039 220.7 122.406 220.362C123.299 219.512 124.339 218.177 125.336 218.192C126.332 218.206 127.343 219.556 128.163 220.494C128.5 220.876 128.353 221.697 128.427 222.328L128.939 222.533Z"
      fill="#88AFEF"
    />
    <path
      d="M138.844 233.24C138.361 235.19 137.76 237.801 137.013 240.382C136.266 242.964 135.548 245.222 134.654 247.628C134.498 248.366 134.064 249.016 133.442 249.442C132.82 249.868 132.058 250.038 131.314 249.916C130.54 249.767 129.847 249.341 129.364 248.717C128.881 248.094 128.642 247.315 128.692 246.528C128.641 245.822 128.711 245.113 128.897 244.43C130.118 240.157 131.363 235.889 132.633 231.626C133.131 229.969 134.01 228.693 136.031 228.81C137.57 228.957 138.8 230.658 138.844 233.24Z"
      fill="#88AFEF"
    />
    <path
      d="M394.866 137.246H391.833C389.441 138.622 386.747 139.386 383.99 139.47C381.233 139.555 378.497 138.957 376.026 137.73C375.802 137.708 375.582 137.663 375.367 137.598C372.1 155.022 368.115 172.886 352.879 177.272C333.028 183.006 316.62 129.488 314.716 126.158C314.144 125.132 312.855 124.12 312.152 124.31C309.061 125.117 311.566 129.4 312.064 130.617C309.808 132.582 306.922 135.853 307.42 138.757C307.659 140.207 308.155 141.603 308.885 142.878C309.442 143.817 312.211 145.958 312.328 146.75C312.328 146.75 319.506 197.6 357.64 195.81C385.021 194.534 399.1 167.166 404.857 138.17C401.605 137.906 398.206 137.657 394.866 137.246Z"
      fill="#F4A28C"
    />
    <path
      opacity="0.08"
      d="M394.866 137.246H391.833C389.441 138.622 386.747 139.386 383.99 139.47C381.233 139.555 378.497 138.957 376.026 137.73C375.802 137.708 375.582 137.663 375.367 137.598C372.1 155.022 368.115 172.886 352.879 177.272C333.028 183.006 316.62 129.488 314.716 126.158C314.144 125.132 312.855 124.12 312.152 124.31C309.061 125.117 311.566 129.4 312.064 130.617C309.808 132.582 306.922 135.853 307.42 138.757C307.659 140.207 308.155 141.603 308.885 142.878C309.442 143.817 312.211 145.958 312.328 146.75C312.328 146.75 319.506 197.6 357.64 195.81C385.021 194.534 399.1 167.166 404.857 138.17C401.605 137.906 398.206 137.657 394.866 137.246Z"
      fill="black"
    />
    <path
      d="M404.537 117.227C404.537 117.227 377.961 111.961 374.226 133.477C372.215 145.804 368.773 157.854 363.971 169.381L384.276 175.967L404.537 117.227Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.46"
      d="M404.537 117.227C404.537 117.227 377.961 111.961 374.226 133.477C372.215 145.804 368.773 157.854 363.971 169.381L384.276 175.967L404.537 117.227Z"
      fill="white"
    />
    <path
      d="M405.693 81.1904C405.693 81.1904 401.855 89.3157 401.196 95.4317C401.135 96.024 401.191 96.6225 401.361 97.193C401.532 97.7635 401.813 98.2948 402.188 98.7565C402.564 99.2182 403.026 99.6014 403.55 99.884C404.073 100.167 404.647 100.343 405.239 100.404C407.138 100.703 409.082 100.447 410.839 99.6669C412.596 98.8863 414.09 97.6143 415.142 96.0037L420.387 89.5797C421.296 88.181 421.75 86.5348 421.687 84.8672C421.624 83.1996 421.047 81.5924 420.036 80.2664C416.549 75.133 407.773 76.1157 405.693 81.1904Z"
      fill="#F4A28C"
    />
    <path
      d="M417.12 91.0759L418.995 117.212L404.535 117.227L408.857 98.6879L417.12 91.0759Z"
      fill="#F4A28C"
    />
    <path
      d="M403.305 87.3223L400.155 89.4343C399.97 89.5486 399.818 89.7084 399.712 89.8983C399.606 90.0883 399.551 90.3021 399.551 90.5196C399.551 90.7371 399.606 90.9509 399.712 91.1409C399.818 91.3308 399.97 91.4906 400.155 91.6049L402.895 93.2036L403.305 87.3223Z"
      fill="#F4A28C"
    />
    <path
      opacity="0.31"
      d="M408.755 99.202C410.504 98.895 412.136 98.1138 413.472 96.9434C413.472 96.9434 414.395 102.605 407.51 104.511L408.755 99.202Z"
      fill="#CE8172"
    />
    <path
      d="M413.15 90.739C413.15 90.739 414.41 87.5124 416.754 88.6711C419.098 89.8297 417.311 94.5377 413.824 93.7017L413.15 90.739Z"
      fill="#F4A28C"
    />
    <path
      d="M378.414 266.049L366.182 321.694H373.287L397.093 266.049H378.414Z"
      fill="#F4A28C"
    />
    <path
      d="M418.203 259.288L431.242 321.694H438.391L437.321 258.848L418.203 259.288Z"
      fill="#F4A28C"
    />
    <path
      d="M366.182 321.695C366.182 321.695 362.241 326.697 357.187 326.462C352.133 326.227 351.4 333.355 359.516 332.622C362.045 332.411 364.55 331.979 367.003 331.331C369.269 330.807 371.633 330.888 373.859 331.566C374.293 331.669 374.748 331.635 375.162 331.47C375.576 331.305 375.929 331.016 376.173 330.642C378.473 327.929 372.819 321.695 372.819 321.695H366.182Z"
      fill="#68E1FD"
    />
    <path
      d="M437.879 321.695C437.879 321.695 441.82 326.697 446.874 326.462C451.929 326.227 452.734 333.355 444.545 332.622C442.017 332.411 439.512 331.979 437.059 331.331C434.792 330.807 432.428 330.888 430.203 331.566C429.769 331.669 429.314 331.635 428.9 331.47C428.485 331.305 428.132 331.016 427.888 330.642C425.588 327.929 431.243 321.695 431.243 321.695H437.879Z"
      fill="#68E1FD"
    />
    <path
      d="M454.8 180.074C447.475 191.705 439.154 206.474 435.477 218.12C434.506 221.003 433.882 223.991 433.616 227.022L377.404 224.177C377.404 224.177 377.829 220.628 378.078 215.098C378.532 205.506 378.474 189.916 374.914 176.745C374.035 173.319 372.808 169.991 371.251 166.816C371.251 166.816 352.104 133.992 404.536 117.213H419.01C419.01 117.213 419.992 117.213 421.706 117.213C453.233 118.225 471.545 153.308 454.8 180.074Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.08"
      d="M440.238 200.709L435.477 218.133L378.078 215.112C378.533 205.52 378.474 189.929 374.914 176.758C390.106 184.209 419.772 196.162 421.003 176.832C422.658 150.813 429.646 136.352 429.646 136.352C455.299 173.737 440.238 200.709 440.238 200.709Z"
      fill="black"
    />
    <path
      d="M378.078 215.098L435.477 218.119L450.801 276.331L367.838 273.368L378.078 215.098Z"
      fill="#24285B"
    />
    <path
      d="M430.114 139.11C430.011 136.545 430.716 134.012 432.129 131.87C433.542 129.728 435.591 128.084 437.988 127.171C440.384 126.259 443.007 126.123 445.485 126.783C447.962 127.444 450.17 128.867 451.796 130.852C459.934 140.652 464.239 153.078 463.912 165.818C463.428 199.874 458.857 236.746 385.461 196.456L389.416 185.662C389.416 185.662 421.983 201.34 435.593 196.251C445.599 192.511 431.154 162.503 430.114 139.11Z"
      fill="#F4A28C"
    />
    <path
      d="M389.418 185.662C389.418 185.662 377.17 179.795 371.97 185.662C366.769 191.529 375.046 196.691 385.462 196.457L389.418 185.662Z"
      fill="#F4A28C"
    />
    <path
      d="M404.257 76.8784L401.43 83.9477L413.15 88.3477C413.15 88.3477 413.809 96.2091 409.692 97.6317C410.628 97.584 411.545 97.3507 412.39 96.9454C413.235 96.54 413.991 95.9707 414.615 95.2704C415.11 94.733 415.794 94.4084 416.523 94.3647C417.252 94.3209 417.97 94.5614 418.526 95.0357L418.673 95.1677C422.015 91.8744 424.365 87.7071 425.456 83.1411C426.789 76.2037 419.068 75.1477 419.068 75.1477C419.068 75.1477 408.857 67.9464 404.257 76.8784Z"
      fill="#24285B"
    />
    <path
      d="M419.098 75.1487C419.098 75.1487 419.64 68.4753 431.609 69.546C443.578 70.6167 431.609 86.8087 441.468 101.901C441.468 101.901 423.361 98.0727 422.833 81.8073L419.098 75.1487Z"
      fill="#24285B"
    />
    <path
      d="M418.352 74.6631C419.26 74.1738 420.291 73.9603 421.319 74.0489C422.347 74.1374 423.327 74.5242 424.138 75.1618C427.537 77.3031 429.031 85.1498 423.699 87.8485C423.699 87.8485 428.123 77.2298 418.352 74.6631Z"
      fill="#FFD200"
    />
    <path
      d="M434.774 175.967H466.037C466.037 175.967 469.465 130.295 443.593 124.443C417.721 118.591 434.774 175.967 434.774 175.967Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.46"
      d="M434.774 175.967H466.037C466.037 175.967 469.465 130.295 443.593 124.443C417.721 118.591 434.774 175.967 434.774 175.967Z"
      fill="white"
    />
    <path
      d="M228.969 275.392H204.445V271.285H224.853V250.84H228.969V275.392Z"
      fill="#88AFEF"
    />
    <path
      d="M68.5234 275.392H93.0475V271.285H72.6254V250.84H68.5234V275.392Z"
      fill="#88AFEF"
    />
    <path
      d="M228.969 59.4844H204.445V63.591H224.853V84.0364H228.969V59.4844Z"
      fill="#88AFEF"
    />
    <path
      d="M68.5234 59.4844H93.0475V63.591H72.6254V84.0364H68.5234V59.4844Z"
      fill="#88AFEF"
    />
    <path
      opacity="0.46"
      d="M228.97 290.924H68.5234V297.319H228.97V290.924Z"
      fill="white"
    />
    <path
      opacity="0.46"
      d="M209.691 307.248H68.5234V313.643H209.691V307.248Z"
      fill="white"
    />
    <path
      d="M191.598 245.457C204.123 245.457 214.276 235.292 214.276 222.753C214.276 210.214 204.123 200.049 191.598 200.049C179.073 200.049 168.92 210.214 168.92 222.753C168.92 235.292 179.073 245.457 191.598 245.457Z"
      fill="#24285B"
    />
    <path
      d="M186.529 236.717L177.373 219.439L183.424 216.227L188.58 225.966L203.626 213.133L208.065 218.339L186.529 236.717Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.58"
      d="M295.789 309.359C295.789 309.359 282.076 305.619 279.088 292.83C279.088 292.83 300.345 288.532 300.946 310.43L295.789 309.359Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.73"
      d="M297.416 308.011C297.416 308.011 287.835 292.846 296.259 278.678C296.259 278.678 312.374 288.944 305.239 308.011H297.416Z"
      fill="#68E1FD"
    />
    <path
      d="M299.934 308.01C299.934 308.01 305.002 291.994 320.312 288.943C320.312 288.943 323.242 299.357 310.394 308.01H299.934Z"
      fill="#68E1FD"
    />
    <path
      d="M290.002 307.658L292.771 326.696L310.278 326.769L312.871 307.746L290.002 307.658Z"
      fill="#24285B"
    />
  </svg>
);

export default Logo;
